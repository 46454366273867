import {
    Component,
    Input,
    Renderer,
    ElementRef,
    OnDestroy
} from '@angular/core';

import { DropdownStateService } from '../../services/dropdown-state.service';

@Component({
    selector: 'ng2-menu-item',
    styles: [`.ng2-menu-item{font-family:Roboto,"Helvetica Neue",Helvetica,Arial;background:#fff;color:rgba(0,0,0,.87);cursor:pointer;font-size:.9em;text-transform:none;font-weight:400;letter-spacing:.03em;height:48px;line-height:48px;padding:.3em 1.25rem;text-overflow:ellipsis;white-space:nowrap;overflow:hidden;transition:background .25s}.ng2-menu-item--selected{background:rgba(158,158,158,.2);outline:0}.ng2-menu-item:focus{outline:0}.ng2-menu-item:active{background:rgba(158,158,158,.4)}:host(ng2-menu-item) /deep/ [ng2-menu-item-icon]{vertical-align:middle;font-size:28px;width:1.5em;height:30px;color:rgba(0,0,0,.44)}`],
    template: `<div class='ng2-menu-item'
     role="button"
     tabindex="0"
     [class.ng2-menu-item--selected]="isSelected"
     (keydown.enter)="click()"
     (click)="click()"
     (mouseover)="select()">
        <ng-content></ng-content>
</div>
`
})
export class Ng2MenuItem implements OnDestroy {
    /**
     * @preventClose
     * @desc if true, clicking on the item won't close the dropdown
     */
    @Input() public preventClose: boolean = false;

    /**
     * @name value
     * @desc any value associated to the item
     */
    @Input() public value: any;

    constructor(private state: DropdownStateService,
                private element: ElementRef,
                private renderer: Renderer) {}

    public ngOnDestroy(): void {
        this.state.dropdownState.onItemDestroyed.emit(this);
    }

    /**
     * @name isSelected
     * @desc returns current selected item
     */
    public get isSelected(): boolean {
        return this === this.state.dropdownState.selectedItem;
    }

    /**
     * @name click
     * @desc emits select event
     */
    public select($event?): void {
        this.state.dropdownState.select(this, true);

        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }
    }

    /**
     * @name click
     * @desc emits click event
     */
    public click(): void {
        this.state.dropdownState.onItemClicked.emit(this);
    }

    /**
     * @name focus
     */
    public focus() {
        this.renderer.invokeElementMethod(this.element.nativeElement.children[0], 'focus');
    }
}
